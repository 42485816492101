import React, { Component } from 'react';
import moment from 'moment';

const RangePicker = Loader.loadBaseComponent('RangePicker');

export default class MassTime extends Component {
  timeChange = (type, value) => {
    const { onChange, startTime, endTime } = this.props;
    let option = { startTime, endTime };
    if (type === 'startTime') {
      option.startTime = parseInt(value / 1000);
    } else {
      option.endTime = parseInt(value / 1000);
    }
    if (type === 'startTime' && option.endTime === undefined) {
      let NOW = moment(new Date()).valueOf();
      option.endTime = parseInt(NOW / 1000);
    }
    onChange && onChange(option);
  };
  render() {
    const { startTime, endTime } = this.props;
    return (
      <RangePicker
        startTime={startTime ? startTime * 1000 : undefined}
        endTime={endTime ? endTime * 1000 : undefined}
        onChange={this.timeChange}
        className="mass-list-timeRagle"
        maxDate={true}
        minDate={-30}
        allowClear={false}
      />
    );
  }
}
