(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define("MassControl", ["React", "moment"], factory);
	else if(typeof exports === 'object')
		exports["MassControl"] = factory(require("React"), require("moment"));
	else
		root["MassControl"] = factory(root["React"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 